.bubble {
  border-radius: 2em;
  border: 2px solid rgb(0,0,0);
  background: rgba(255, 255, 255, .75);
  backdrop-filter: blur(50px);
  display: flex;
  padding: 1.5em 2.5em;
  margin: 0;
  font-size: 1.25em;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 0.5rem 0 #000;
  max-width: 80%;
  text-align: center;
  line-height: 1.5;
  user-select: none;
  flex-direction: column;
}

.bubble::selection, h1::selection {
  background: yellow;
}

.bubble > p {
  margin: 0;
}

.shake {
  animation: shake 5s ease infinite;
}
@keyframes shake {
  0%, 100% {transform: translateX(0);}
  10%, 30%, 50%, 70%, 90% {transform: translateX(-.25em);}
  20%, 40%, 60%, 80% {transform: translateX(.25em);}
}

.new {
  left: -5%;
  position: absolute;
  scale: 1.5;
  top: -5%;
  transform: rotate(-30deg);
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.transition > div {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
}
